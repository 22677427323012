/* eslint-disable class-methods-use-this */
import axios from '@axios'

const API_URL = 'https://psi-wwkeahz7bq-uc.a.run.app'
//const API_URL = 'http://localhost:3000'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getHistory(siteId, range = 'week') {
      return new Promise(resolve => {
        axios.get(`${API_URL}/v2/site/history/${siteId}?range=${range}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            resolve(error)
          })
      })
    },
    getItems(site, desktop, mobile) {
      return new Promise(resolve => {
        axios.get(`${API_URL}/v2/site/results/tooltip?id=${site}&d=${desktop}&m=${mobile}`)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            resolve(error)
          })
      })
    },
  },
}
