var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"module-table"},[_c('table',{staticClass:"w-full text-sm text-left mt-4 border table-fixed md:table-auto"},[_c('thead',{staticClass:"text-xs text-gray-700 uppercase bg-white "},[_c('tr',_vm._l((_vm.details.headings),function(header,index){return _c('th',{staticClass:"tw-row line-height-1 h-16 font-weight-normal text-capitalize align-middle text-base text-gray-400",class:[
              'audit-col-' + (String(header.valueType).toLowerCase() || 'text'),
              header.label && header.key !== 'node' ? '' : 'hidden' ],attrs:{"data-col":JSON.stringify(header)}},[_vm._v(" "+_vm._s(header.label ? header.label : '')+" ")])}),0)]),_c('tbody',_vm._l((_vm.allRows),function(row,index){return _c('tr',{key:'row_' + index,staticClass:"hover:bg-gray-50"},_vm._l((_vm.details.headings),function(heading){return _c('td',{staticClass:"tw-row table-cell",class:{
            'bg-gray-100' : row.rowType === 'group',
            'pl-2': row.rowType === 'item',
            'text-right font-semibold': _vm.isNumericCell(heading),
            'hidden': !heading.label && heading.key !== 'node'
          }},[(_vm.getItemValue(row, heading) && !_vm.getItemValue(row, heading).hasPlain)?_c('div',{attrs:{"data-value":JSON.stringify(row),"data-heading":JSON.stringify(heading)},domProps:{"innerHTML":_vm._s(_vm.getItemValue(row, heading))}}):_c('div',{class:{
              'node-cell': heading.valueType === 'node'
            },attrs:{"data-value":JSON.stringify(row),"data-heading":JSON.stringify(heading)}},[_c('span',{staticClass:"inline",domProps:{"innerHTML":_vm._s(_vm.getItemValue(row, heading) && _vm.getItemValue(row, heading).htmlElement)}}),_c('span',{staticClass:"text-blue-500 font-mono ml-2",staticStyle:{"line-height":"20px"}},[_vm._v(" "+_vm._s(_vm.getItemValue(row, heading) && _vm.getItemValue(row, heading).snippet)+" ")])])])}),0)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }