var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-audit w-full flex items-center transition-all relative",class:{
      'border-b border-gray-200': _vm.isOpen,
  },on:{"click":function($event){$event.stopPropagation();return _vm.$emit('click')}}},[_c('div',{staticClass:"absolute left-0 top-0 h-full flex items-center pl-2"},[_c('span',{staticClass:"rounded-full w-4 h-4 ml-1 inline-block",class:[
            _vm.getAuditBadgeColor(),
            this.ratingLabel === 'informative' ? 'border-1 border-gray-600' : ''
            ]})]),_c('div',{staticClass:"p-1 px-2 ml-3 text-lg"},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.displayValue)?_c('span',{staticClass:"display-val"},[_vm._v(" — "+_vm._s(_vm.displayValue)+" ")]):_vm._e()]),_c('div',{staticClass:"absolute right-0 top-0 h-full flex items-center pr-5"},[(_vm.score)?_c('span',{staticClass:"rounded-full text-white",class:_vm.getAuditBadgeColor(),staticStyle:{"padding":"5px 10px"}},[_vm._v(_vm._s(_vm.score))]):_vm._e()]),_c('div',{staticClass:"absolute right-0 top-0 h-full flex items-center pr-2"},[_c('svg',{staticClass:"transition-all",class:{
            'transform rotate-180': _vm.isOpen,
        },attrs:{"width":"1em","height":"1em","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","d":"m6 9l6 6l6-6"}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }