<template>
  <div v-if="config.elements" class="custom-chart-tooltip">
    <div class="apexcharts-tooltip-title">
      {{ config.title }}
    </div>
    <div
      v-for="(element, index) in config.elements"
      :key="index"
      class="apexcharts-tooltip-series-group apexcharts-active"
    >
      <span
        class="apexcharts-tooltip-marker"
        :style="{ backgroundColor: element.markerColor }"
      />
      <div class="apexcharts-tooltip-text">
        <div class="apexcharts-tooltip-y-group">
          <span
            v-if="element.label"
            class="apexcharts-tooltip-text-y-label"
          >
            {{ element.label }}:
          </span>
          <span
            class="apexcharts-tooltip-text-y-value"
          >{{ element.value }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomChartTooltip',
  props: {
    config: {
      type: Object,
      require: true,
      default: () => {},
    },

  },
}
</script>

<style lang="scss" scoped>
.apexcharts-tooltip-title {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 12px;
}

.apexcharts-tooltip-series-group {
    order: 1;
    display: flex;
}

.apexcharts-tooltip-text {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 12px;
}

.apexcharts-tooltip-y-group > a:hover {
    text-decoration: underline;
    cursor: pointer;
}
</style>
