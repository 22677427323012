<template>
  <div class="pageScore generalScore">
    <h3>Page Score
      <span v-if="date">- {{ date }}</span>
    </h3>
    <b-row class="d-md-flex align-items-center">
      <b-col md="5">
        <PageScoreChart :updates="updates" />
      </b-col>
      <b-col md="7" class="info flex align-items-center">
        <div>
          <p class="mb-2">Optimize and improve your <br> Page Score with us</p>
          <ol>
            <li><span class="dot bad" /> 0-49 Bad</li>
            <li><span class="dot average" /> 50-89 Average</li>
            <li><span class="dot excellent" /> 90-100 Excellent</li>
          </ol>
          <button v-show="false" class="button-seocloud-outline">
            Fix this
          </button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import PageScoreChart from './Components/Chart/PageScore.vue'

export default {
  components: {
    BCol,
    BRow,
    PageScoreChart,
  },
  props: {
    updates: {
      type: Object,
      required: true,
      default: () => {},
    },
    date: {
      type: String,
      required: true,
    },
  },
}
</script>
