import store from "@/store";
import {
    _renderBytes,
    _renderMilliseconds,
    formatNumber,
    renderNode,
    renderSourceLocation,
    renderTextURL
} from "@core/utils/tableUtils";

export const URL_PREFIXES = ['http://', 'https://', 'data:']

export const getCell = (heading, item) => {
    return item[heading.key]
}

export const getItemValue = (item, heading) => {

    const value = getCell(heading, item)

    // First deal with the possible object forms of value.
    if (typeof value === 'object') {

        // The value's type overrides the heading's for this column.
        switch (value.type) {
            case 'code': {
                return `<pre>${String(value.value)}</pre>`
            }
            case 'link': {
                return `<a href="${value.url}" target="_blank" rel="noopener">${value.text}</a>`
            }
            case 'node': {
                const selectedType = store.getters['results/getActualType']
                const results = store.getters['results/getPsiResults']
                const screenshots = results[selectedType].data.fullPageScreenshot
                return renderNode(value, screenshots)
            }
            case 'numeric': {
                const numValue = Number(value.value);
                return formatNumber(numValue, heading.granularity);
            }
            case 'source-location': {
                return renderSourceLocation(value)
            }
            case 'url': {
                return renderTextURL(value.value);
            }
            default: {
                //console.error('Unknown valueType', heading.type);
                return value
            }
        }
    }

    switch (heading.valueType) {
        case 'code': {
            return `<pre>${String(value)}</pre>`
        }
        case 'link': {
            return `<a href="${value.url}" target="_blank" rel="noopener">${value.text}</a>`
        }
        case 'ms': {
            const msValue = {
                value: Number(value),
                granularity: heading.granularity,
                displayUnit: heading.displayUnit,
            };
            return _renderMilliseconds(msValue);
        }
        case 'bytes': {
            const numValue = Number(value);
            return _renderBytes({value: numValue, granularity: heading.granularity});
        }
        case 'text': {
            return String(value)
        }
        case 'url': {
            let strValue = String(value);
            if (URL_PREFIXES.some(prefix => strValue.startsWith(prefix))) {
                return renderTextURL(strValue);
            } else {
                // Fall back to <pre> rendering if not actually a URL.
                // remove " if present
                strValue = strValue.replace(/"/g, '');

                // if is undefined
                if (strValue === 'undefined') {
                    return `<pre></pre>`;
                }

                return item.rowType === 'group' ? strValue : `<pre></pre>`;
            }
        }
        case 'numeric': {
            const numValue = Number(value);
            return formatNumber(numValue, heading.granularity);
        }
        case 'timespanMs': {
            //console.log('item value', value,_renderMilliseconds(value), heading, item)
            return _renderMilliseconds({ value });
        }
        case 'thumbnail': {
            const strValue = String(value);
            return `<img src="${strValue}" alt="Thumbnail" />`
        }
        case 'source-location': {
            return renderSourceLocation(value)
        }
        default: {
            //console.error('Unknown valueType', heading.valueType);
            return JSON.stringify(value)
        }
    }

}
