<template>
  <apex
    v-if="optionsChart"
    type="radialBar"
    height="280px"
    width="280px"
    :options="optionsChart.chartOptions"
    :series="optionsChart.series"
  />
</template>

<script>
import apex from "vue-apexcharts";
export default {
  components: {
    apex,
  },
  props: {
    updates: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  watch: {
    updates() {
      this.optionsChart.series = [this.updates.scores];
      this.optionsChart.chartOptions.labels[0] = this.updates.labels;
      this.optionsChart.chartOptions.fill.colors[0] = this.updates.colors;
    },
  },
  created() {
    this.optionsChart.series = this.updates.scores;
    this.optionsChart.chartOptions.labels[0] = this.updates.labels;
    this.optionsChart.chartOptions.fill.colors[0] = this.updates.colors;
  },
  data() {
    return {
      optionsChart: {
        series: [0],
        chartOptions: {
          chart: {
            height: 350,
            type: "radialBar",
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: "70%",
              },
              dataLabels: {
                showOn: "never",
                name: {
                  color: "#232C51",
                  fontSize: "69px",
                  show: true,
                },
                value: {
                  color: "#232C51",
                  fontSize: "36px",
                  show: true,
                  formatter(val) {
                    if (val >= 0 && val <= 39) return "bad";
                    if (val >= 40 && val <= 59) return "average";
                    if (val >= 60 && val <= 89) return "good";
                    if (val >= 90) return "excellent";
                    return "";
                  },
                },
              },
            },
          },
          fill: {
            colors: ["#FC7D7D"],
            opacity: 1,
          },
          stroke: {
            lineCap: "round",
          },
          labels: ["wait"],
        },
      },
    };
  },
};
</script>