import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// Components
const performanceComponent = () => import('@/views/Performance.vue')
const error404 = () => import('@/views/error/Error404.vue')

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/login',
      redirect: () => {
        window.location.href = 'https://app.seocloud.ai/login'
        return '/redirecting' // not important since redirecting
      },
    },
    {
      path: '/sign-up',
      redirect: () => {
        window.location.href = 'https://app.seocloud.ai/sign-up'
        return '/redirecting' // not important since redirecting
      },
    },
    {
      path: '/seocloud',
      redirect: () => {
        window.location.href = 'https://app.seocloud.ai/'
        return '/redirecting' // not important since redirecting
      },
    },
    {
      path: '/:id?',
      name: 'performance',
      component: performanceComponent,
      meta: {
        layout: 'full',
        pageTitle: 'Performance',
      },
    },
    {
      path: '/:id?/:key?',
      name: 'performance-history',
      component: performanceComponent,
      meta: {
        layout: 'full',
        pageTitle: 'Performance',
      },
    },
    {
      path: '/error/404',
      name: 'error-404',
      component: error404,
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
